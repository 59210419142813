import { Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { useState } from "react";
import FormInput from "components/form/FormInput";
import schemaCumulativeSetting from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RARITY_LABEL } from "constants/constants";

export default function CostFusionRow ({
  cumulative,
  onSave
}) {
  const [isEdit, setIsEdit] = useState(false);
  const form = useForm({
    resolver: yupResolver(schemaCumulativeSetting()),
    defaultValues: {
      fusion_bcoin_fee: cumulative ? cumulative?.fusion_bcoin_fee : "",
      fusion_sphere_fee: cumulative ? cumulative?.fusion_sphere_fee : "",
      awaken_bcoin_fee: cumulative ? cumulative?.awaken_bcoin_fee : "",
      awaken_sphere_fee: cumulative ? cumulative?.awaken_sphere_fee : "",
    }
  });
  const {
    setValue,
    handleSubmit,
  } = form;
  const onSubmit = form.handleSubmit((data) => {
    if (onSave) {
      onSave(cumulative?.id, data);
    }
    setIsEdit(false);
  });

  return (
    <>
      { isEdit ? (
        <FormProvider {...form}>
          <Tr>
            <Td
              width="20%"
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {RARITY_LABEL[cumulative?.rarity]}
            </Td>
            <Td>
              <Flex alignItems="center" gap="8px">
                <FormInput
                  label=""
                  name="fusion_bcoin_fee"
                  type="number"
                  placeholder="Enter number bcoin"
                  onChange={(e) => {
                    setValue("fusion_bcoin_fee", e.target.value);
                  }}
                />
                &
                <FormInput
                  label=""
                  name="fusion_sphere_fee"
                  type="number"
                  placeholder="Enter number sphere"
                  onChange={(e) => {
                    setValue("fusion_sphere_fee", e.target.value);
                  }}
                />
              </Flex>
            </Td>
            <Td>
              <Flex alignItems="center" gap="8px">
                <FormInput
                  label=""
                  name="awaken_bcoin_fee"
                  type="number"
                  placeholder="Enter number bcoin"
                  onChange={(e) => {
                    setValue("awaken_bcoin_fee", e.target.value);
                  }}
                />
                &
                <FormInput
                  label=""
                  name="awaken_sphere_fee"
                  type="number"
                  placeholder="Enter number sphere"
                  onChange={(e) => {
                    setValue("awaken_sphere_fee", e.target.value);
                  }}
                />
              </Flex>
            </Td>
            <Td width="10%">
              <Flex gap="10px" justifyContent="flex-end">
                <Icon
                  fontSize="30px"
                  borderRadius="8px"
                  cursor="pointer"
                  bg="#07CF84"
                  as={CheckIcon}
                  onClick={onSubmit}
                />
              </Flex>
            </Td>
          </Tr>
        </FormProvider>
      ) : (
        <Tr>
          <Td
            width="20%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {RARITY_LABEL[cumulative?.rarity]}
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Flex gap={6}>
              <Text w="100%" textAlign="center" p="2px 0" bg="#fff9aa" borderRadius="12px">
                {cumulative?.fusion_bcoin_fee || 0}
              </Text>
              &
              <Text w="100%" textAlign="center" p="2px 0" bg="#ebaaff" borderRadius="12px">
                {cumulative?.fusion_sphere_fee || 0}
              </Text>
            </Flex>
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Flex gap={6}>
              <Text w="100%" textAlign="center" p="2px 0" bg="#fff9aa" borderRadius="12px">
                {cumulative?.awaken_bcoin_fee || 0}
              </Text>
              &
              <Text w="100%" textAlign="center" p="2px 0" bg="#ebaaff" borderRadius="12px">
                {cumulative?.awaken_sphere_fee || 0}
              </Text>
            </Flex>
          </Td>
          <Td width="10%">
            <Flex gap="10px" justifyContent="flex-end">
              <Icon
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
                onClick={() => setIsEdit(true)}
              />
            </Flex>
          </Td>
        </Tr>
      )}
    </>
  );
}
