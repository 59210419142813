export const SALE_STATUS = {
  NEW: "NEW",
  SALE: "SALE",
  READY_FOR_SALE: "READY_FOR_SALE",
  BUYING: "BUYING",
  SOLD: "SOLD",
  RE_SALE: "RE_SALE",
  FAIL: "FAIL",
  DELETED: "DELETED",
  REJECT: "REJECT",
  TOKEN_PENDING: "TOKEN_PENDING",
  CANCEL_LISTING: "CANCEL_LISTING",
  LISTING: "LISTING",
  TRANSFERRING: "TRANSFERRING",
};

export const SALE_STATUS_OPTIONS = [
  { label: "New", value: SALE_STATUS.NEW },
  { label: "Sale", value: SALE_STATUS.SALE },
  { label: "Ready for sale", value: SALE_STATUS.READY_FOR_SALE },
  { label: "Buying", value: SALE_STATUS.BUYING },
  { label: "Sold", value: SALE_STATUS.SOLD },
  { label: "Re-sale", value: SALE_STATUS.RE_SALE },
  { label: "Fail", value: SALE_STATUS.FAIL },
  { label: "Deleted", value: SALE_STATUS.DELETED },
  { label: "Token pending", value: SALE_STATUS.TOKEN_PENDING },
  { label: "Cancel listing", value: SALE_STATUS.CANCEL_LISTING },
  { label: "Listing", value: SALE_STATUS.LISTING },
  { label: "Transferring", value: SALE_STATUS.TRANSFERRING },
];

export const NFT_MANAGEMENT_TYPE = {
  HUNTER: "HUNTER",
  GAUNTLET: "GAUNTLET",
  BOUNTY_BALL: "BOUNTY_BALL",
  GENESIS: "GENESIS",
  OWNER: "OWNER",
};

export const NFT_MANAGEMENT_TYPE_LABEL = [
  "NO",
  "IMAGE",
  "NAME",
  "CHAIN",
  "RARITY",
  "TRAIT",
  "ALLOCATION",
  "TOKEN TYPE",
  "",
];

export const NFT_MANAGEMENT_TYPE_LABEL_BOUNTY = [
  "NO",
  "IMAGE",
  "NAME",
  "CHAIN",
  "RARITY",
  "TRAIT",
  "GIMMICK",
  "ALLOCATION",
  "TOKEN TYPE",
  "",
];

export const NFT_MANAGEMENT_TYPE_LABEL_GENESIS = [
  "NO",
  "IMAGE",
  "NAME",
  "CHAIN",
  "RARITY",
  "PACKAGE",
  "ALLOCATION",
  "PRICE",
  "TOKEN TYPE",
  "",
];

export const NFT_MANAGEMENT_TYPE_LABEL_OWNER = [
  "NO.",
  "EMAIL",
  "USERNAME",
  "NUM OF ACTIVE NFT",
  "CURRENT MONTH PAYOUT",
  "TASK STATUS",
  "",
];

export const GENESIS_NFT_TABS_VALUES = {
  IMMUTABLE: "immutable",
  DM2C: "dm2c",
  POLYGON: "polygon",
  BNB_CHAIN: "bnb-chain",
};

export const GENESIS_NFT_TABS = [
  // {
  //   name: "Polygon",
  //   value: GENESIS_NFT_TABS_VALUES.POLYGON,
  // },
  {
    name: "Immutable Chain",
    value: GENESIS_NFT_TABS_VALUES.IMMUTABLE,
  },
  {
    name: "DM2C Chain",
    value: GENESIS_NFT_TABS_VALUES.DM2C,
  },
  // {
  //   name: "BNB Chain",
  //   value: GENESIS_NFT_TABS_VALUES.BNB_CHAIN,
  // },
];

export const PACKAGE_STATUS_VALUE = {
  LISTING: "LISTING",
  DELIST: "DELIST",
};

export const PACKAGE_STATUS_OPTIONS = [
  { label: "Listing", value: PACKAGE_STATUS_VALUE.LISTING },
  { label: "Delist", value: PACKAGE_STATUS_VALUE.DELIST },
];

export const DEFAULT_FILTER_PARAMS = {
  "mint-status": "MINTED",
  sortColumn: "id-desc",
  "sale-status": "",
  query: "",
  chain: "",
  allocation: "",
  rarity: "",
  trait: "",
  page: 1,
  limit: 10,
};

export const DEFAULT_FILTER_GENESIS_PARAMS = {
  "mint-status": "MINTED",
  sortColumn: "id-desc",
  "sale-status": "",
  query: "",
  package: "",
  allocation: "",
  rarity: "",
  trait: "",
  page: 1,
  limit: 10,
};

export const DEFAULT_FILTER_PACKAGE_GENESIS_PARAMS = {
  sortColumn: "id-desc",
  page: 1,
  limit: 10,
};

export const DEFAULT_FILTER_OWNER_PARAMS = {
  sortColumn: "id-desc",
  page: 1,
  limit: 10,
};

export const NFT_MANAGEMENT_PARAMS = {
  [NFT_MANAGEMENT_TYPE.GENESIS]: DEFAULT_FILTER_GENESIS_PARAMS,
  [NFT_MANAGEMENT_TYPE.OWNER]: DEFAULT_FILTER_OWNER_PARAMS,
};

export const WALLETS_TYPE = {
  BICONOMY: "BICONOMY",
  DM2: "DM2",
  IMMUTABLE: "IMMUTABLE",
};
