import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getLeaderboardStandard } from "api/event.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import LeaderBoardRow from "./row";
import ConditionLeaderBoard from "./action";
import ButtonType from "components/Button";
import LeaderBoardException from "./LeaderBoardException";
import { convertParams, omitParams } from "utils/object";
import { downloadCSV } from "utils/downloadCSV";

export default function ModalLeaderBoard({
  isOpen,
  onClose,
  dataDetail
}) {
  const labels = [
    "Pos",
    "User",
    "Plays",
    "Pts",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");

  const {
    isOpen: isOpenExceptions,
    onOpen: onOpenExceptions,
    onClose: onCloseExceptions,
  } = useDisclosure();

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getLeaderboardStandard(dataDetail?.id, {
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setLeaderBoard(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const downloadLeaderBoard = async () => {
    try {
      const param = convertParams(omitParams(params));
      const baseUrl = `standard-leaderboards/export-csv/${dataDetail?.id}?${param}`;
      await downloadCSV(baseUrl, true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {dataDetail?.name} - LEADER BOARD
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                {/* <Text 
                  fontSize="14px" 
                  fontWeight="500" 
                  padding="8px 12px" 
                  background="red" 
                  borderRadius="12px" 
                  color="#fff" 
                  w="max-content"
                  onClick={onOpenExceptions}
                  cursor="pointer"
                >
                  Exclude from Leaderboard
                </Text> */}
                <ConditionLeaderBoard
                  isLoading={isLoading}
                  setParams={setParams}
                  params={params}
                />
                <Flex justifyContent="flex-end">
                  <ButtonType
                    text="CSV"
                    fontSize="12px"
                    sizeIcon="16px"
                    iconType="download"
                    btnType="dark"
                    onClick={downloadLeaderBoard}
                  />
                </Flex>
              </CardHeader>
              <CardBody overflowX="auto">
                <TableCustom
                  isCheck={false}
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={leaderBoard}
                >
                  <>
                    {leaderBoard?.map((item, index) => {
                      return (
                        <LeaderBoardRow
                          key={item?.id}
                          item={item}
                          index={index}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
      </ModalContent>
      {isOpenExceptions && (
        <LeaderBoardException 
          isOpen={isOpenExceptions}
          onClose={onCloseExceptions}
          dataDetail={dataDetail}
        />
      )}
    </Modal>
  );
}
  