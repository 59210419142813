export const ENTRY_REQUIREMENT = [
    { label: "None", value: 'NONE' },
    { label: "NFT Certificate", value: "NFT_CERTIFICATE" },
];

export const ERROR_CREATE_EVENT = {
    "INVALID_MODE_TYPE": "Invalid mode type",
    "WHITELIST_MUST_NOT_EMPTY": "Whitelist must not empty",
    "WIN_POINT_MUST_NOT_EMPTY": "Win point must not empty",
    "INVALID_WIN_POINT": "Invalid win point",
    "ENTRY_REQUIREMENT_MUST_NOT_EMPTY": "Entry requirement must not empty",
    "MIN_MATCHES_REQUIREMENT_MUST_NOT_EMPTY": "Min matches requirement must not empty",
    "INVALID_MIN_MATCHES_REQUIREMENT": "Invalid min matches requirement",
    "EVENT_IS_NOT_EXIST": "Event is not exist",
    "EVENT_TIME_OVERLAPS_WITH_AN_EXISTING_EVENT": "Event time overlaps with an existing event",
    "EVENT_START_TIME_AND_END_TIME_ARE_NOT_VALID": "Event start time and end time are not valid",
}

export const ERROR_WHITELIST = {
    "EVENT_DOES_NOT_SUPPORT_A_WHITELIST": "Event does not support a whitelist",
    "FAILED_TO_UPLOAD_FILE": "Failed to upload file",
    "EMAIL_NOT_FOUND": "Email not found",
    "EVENT_NOT_FOUND": "Event not fount",
    "USER_IS_EXIST_IN_WHITELIST": "User is exist in whitelist"
}

export const CASES_SKILL_POINT = {
    "GET_PRIZE": "Get prize",
    "WIN": "Win",
    "LOSE": "Lose",
    "EXIT": "Exit",
    "WIN_HIGHER": "Win higher rank",
    "WIN_LOWER": "Win lower rank",
    "LOSE_HIGHER": "Lose higher rank"
}

export const MODE_TYPE_SKILL_RANK = [
    { label: "PVE Single Play", value: "PVE_SINGLE_PLAY" },
    { label: "PVP", value: "PVP" },
]
