import { StorageKeys } from "constants/enum";
import { getLocalStorage } from "./storage";
import BASE_API from "constants/configUrl";
import moment from "moment";

export const downloadCSV = async (baseUrl, isOnlyFileName = false, body = {}) => {
  const token = getLocalStorage(StorageKeys.TOKEN) || "";
  const formatDate = "YYYYMMDDHHmmss";
  const downloadUrl = Object.values(body)?.length
    ? `${BASE_API.BASE_API_URL}/api/${baseUrl}?` + new URLSearchParams(body).toString()
    : `${BASE_API.BASE_API_URL}/api/${baseUrl}`;

  const response = await fetch(downloadUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const blob = await response.blob();
  const contentDisposition = response.headers.get("Content-Disposition");
  let filename = contentDisposition?.split("=")[1] || "player.csv";
  if (contentDisposition && contentDisposition.includes("attachment")) {
    const matches = contentDisposition?.split("=");
    const fileName = matches[1];
    if (fileName && !isOnlyFileName) {
      const decodedString = decodeURIComponent(fileName);
      const decodedSplit = decodedString?.split(".csv")?.[0]?.split("_");
      if (decodedSplit?.[1]) {
        const utcString = decodedSplit?.[1];
        const utcTime = moment.utc(utcString, formatDate);
        const localTime = utcTime.local().format(formatDate);
        filename = `${decodedSplit?.[0]}_${localTime}.csv`;
      } else {
        filename = `${decodedSplit?.[0]}.csv`;
      }
    } else {
      filename = fileName;
    }
  }
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadLocalCSV = async (csv, fileName) => {
  const response = await fetch(csv);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
